import React, { Component } from "react";
import SkillsGroup from "./SkillGroup";

class Resume extends Component {
  getRandomColor() {
    let letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  getColor(num) {
    const compare = Number(num.slice(0, -1));
    if (compare >= 80) {
      return "#F2B807";
    } else if (compare >= 60) {
      return "#049DD9";
    } else if (compare >= 40) {
      return "#88212C";
    } else if (compare >= 20) {
      return "#D7483E";
    }
    return "#D4C7D9";
  }

  render() {
    if (!this.props.data) return null;

    const skillsGroups = this.props.data.skills2.map((skills) => {
      return (
        <div>
          <SkillsGroup skills={skills} />
        </div>
      );
    });


    return (
      <section id="resume">
        <div className="row skill">
          <div className="three columns header-col">
            <h1>
              <span></span>
            </h1>
          </div>

          <div className="nine columns main-col">
            <div></div>

            <div className="bars">
              {skillsGroups}
            </div>
          </div>
        </div>

      </section>
    );
  }
}

export default Resume;
