import Lottie from "lottie-react";
import animationDark from "../assets/allgenres-darkmode.json";
import React, { useState, useRef, useEffect } from "react";

// const colors = {
//   light: {
//     opacitiy: 1,
//   },
//   dark: {
//     opacity: 0.6,
//   },
// };

function SelectButton({ show }) {
  //console.log()("selectedItems", selectedItems);
  const [selected, setSelected] = useState(false);
  const player = useRef();
  //   const styles = {
  //     wrapper: css({
  //       height: 32,
  //       width: 32,
  //       opacity: 0.8,
  //       cursor: "pointer",
  //     }),
  //   };

  useEffect(() => {
    if (show) {
      player.current.setDirection(1);
      player.current.setSpeed(1);
      player.current.play();
      setSelected(true);
    }
    if (!show) {
      player.current.setDirection(-1);
      player.current.setSpeed(2);
      player.current.play();
      setSelected(false);
    }
  }, [show]);

  //   const handleClick = () => {
  //     player.current.setSpeed(1);
  //     player.current.setDirection(selected ? -1 : 1);
  //     player.current.play();
  //     // if (!selected) {
  //     //   const newSelectedObj = Object.keys(selectedItems).reduce((acc, curr) => {
  //     //     acc[curr] = true;
  //     //     return acc;
  //     //   }, {});
  //     //   setSelected(newSelectedObj);
  //     // } else {
  //     //   const newSelectedObj = Object.keys(selectedItems).reduce((acc, curr) => {
  //     //     acc[curr] = false;
  //     //     return acc;
  //     //   }, {});
  //     //   setSelected(newSelectedObj);
  //     // }
  //     setSelected(!selected);
  //   };
  return (
    <div className="select-button">
      <Lottie
        animationData={animationDark}
        autoplay={false}
        loop={false}
        lottieRef={player}
      />
    </div>
  );
}

export default SelectButton;
