import React from "react";

function getColor(num) {
    const compare = Number(num.slice(0,-1))
    if (compare >= 80) {
      return "#F2B807"
    } else if (compare >= 60) {
      return "#049DD9"
    } else if (compare >= 40) {
      return "#88212C"
    } else if (compare >= 20) {
      return "#D7483E"
    }
    return "#D4C7D9"
  }

function Skill({ skill }) {
    const backgroundColor = getColor(skill.level);
    const className = "bar-expand " + skill.name.toLowerCase();
    const width = skill.level;

  return (
        <li key={skill.name}>
          <span style={{ width, backgroundColor }} className={className}></span>
          <em>{skill.name}</em>
        </li>
  );
}

export default Skill;

