import React, { useState } from "react";
import Skill from "./Skill";
import SelectButton from "./SelectButton";

function SkillsGroup({ skills }) {
  const [show, setShow] = useState(false);

  const handleDropClick = () => {
    setShow(!show);
  };
  return (
    <div className="skills-group">
      <div onClick={handleDropClick} className="skills-heading">
        <SelectButton show={show} />
        <div className="skills-heading-name">{skills.heading}</div>
      </div>

      {show && (
        <div>
          <p className="skills-description">{skills.description}</p>
          <ul className="skills">
            {skills.skills.map((skill) => {
              return <Skill skill={skill} />;
            })}
          </ul>
        </div>
      )}
    </div>
  );
}

export default SkillsGroup;
